import { Box, Flex, Stack, Center, Text, Image } from '@chakra-ui/react';
import React from 'react';

import hero from '../assets/img/heronew.png'
import Logo from '../assets/img/AIME_white.png'
import Logo2 from '../assets/img/INNOVATION WITHOUT LIMITS2.png'
import Logo3 from '../assets/img/SPORTS & MEDIA.svg'


const BackgroundVideo = () => {

    return (

        <Stack position="relative" justifyContent="center" h={["364px", "729px"]} alignItems="center" p={0} m={0}
            style={{
                backgroundImage: 'url(' + hero + ')',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                color: "white",
            }}
        >

            <Stack className="Content" p={7} spacing={4}>

                <Image src={Logo} />
                <Image src={Logo3} />
                <Image src={Logo2} pt={["14px","29px"]}/>
            </Stack>
        </Stack>
    )
}


function HeroSection(props) {
    return (
        <>  <a name="hero"></a>

            <Box p={0} m={0} bg="white" w="100%">
                <BackgroundVideo />
                <Stack align="center" mt={["39px", "78px"]} pb={["39px", "78px"]} px={["20px", "60px"]} spacing={5} >
                    <Text fontSize={["16px", "18px"]} textAlign="center" color="#636F8E" w={["100%", "969px"]} >
                        AIME [ˈeɪmi] stands for artificially intelligent me, representative of the ground-breaking technology we’ve created to fuel the startups, entrepreneurs and established businesses we work with. We emphasise the ‘me’ part as we exist to create bespoke AI-enabled digital products for businesses that are as unique as their founders’ vision. <br />



                    </Text>
                    <Text fontSize={["16px", "18px"]} textAlign="center" color="#636F8E" w={["100%", "969px"]}>

                        Our approach is what sets us apart: we believe in innovation without limits. We flip the development cycle on its head and put the end user at the centre of everything, streamlining processes to enable products to go to market faster and more cost effectively, without compromising on quality.
                    </Text>
                    <Text fontSize={["16px", "18px"]} textAlign="center" color="#636F8E" w={["100%", "969px"]}>

                        The end result? Digital products that are focussed on user needs, scalable and solutions-oriented, and
                        business owners who are able to easily pivot based on feedback.
                    </Text>
                </Stack>

            </Box>
        </>







    );
}

export default HeroSection;