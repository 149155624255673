import { Button } from '@chakra-ui/button';
import { Box, GridItem, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Image,
    Textarea,
    Input,
} from "@chakra-ui/react"
import React from 'react';

import Logo from '../assets/img/AIME7.svg'
import Close from '../assets/img/add.svg'

function Process(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <a name="process"></a>
            <Stack align="center" py={["38px", "76px"]} spacing={4} px={4}>
                <Text fontSize={["24px", "35px"]} textAlign="center" fontWeight="700" mb={["0px", "10px"]} color="#636F8E">Our Process</Text>
                <Text fontSize="18px" color="#636F8E" textAlign="center" w={["100%", "1009px"]} mb="20px" >
                    We are a business builder – helping you get from idea to business.<br />

                We work hand-in-hand with you as a partner, therefore we are careful who we work with. If you think you can change the game for the sports and media industry, pitch your business concept in confidence below and our team will get back to you.<br />
                    <br />
                Our team will evaluate each proposition on its merits, potential and concept. In addition, 1 in 5 of our ventures are focussed on positive social impact and we are always keen to encourage ideas that meet this criteria.<br />


                </Text>
                <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="316px" h="50px" borderRadius="5px" bg="#636F8E" _hover={{ cursor: "pointer", bg: "#EDEEF3", color: "#636F8E" }} onClick={onOpen}>PITCH YOUR BUSINESS CONCEPT</Button>
            </Stack>


            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>
                   
                  
                    <ModalBody>
                        <HStack w="100%"justify="flex-end">
                        <Image src={Close} w="25px"alignSelf="flex-end"m={2} onClick={onClose}/>
                        </HStack>
                    
                        <SimpleGrid columns={[1,5]} gap={12} p={7} >
                            <Box>
                                <Image src={Logo} />
                            </Box>
                            <GridItem colSpan={4} w={["100%","344px"]}>
                                <Stack spacing={8} >
                                    <Text fontSize="20px" fontWeight="900" color="#636F8E">PITCH YOUR BUSINESS CONCEPT</Text>
                                    <Textarea
                                        placeholder="Tell us more about your business concept"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                        textColor="#636F8E"
                                        fontSize="14px"
                                    />
                                    <Textarea
                                        placeholder="Why are you the right person/people to do this? "
                                        textColor="#636F8E"
                                        fontSize="14px"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                    />
                                    <Textarea
                                        placeholder="What stage is your concept at?"
                                        textColor="#636F8E"
                                        fontSize="14px"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                    />
                                    <Input
                                        placeholder="CONTACT NAME"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                    />
                                    <Input
                                        placeholder="EMAIL ADDRESS"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                    />
                                    <Input
                                        placeholder="PHONE (OPTIONAL)"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                    />
                                    <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E" _hover={{ cursor: "pointer", bg: "#EDEEF3", color: "#636F8E" }} onClick={onOpen} alignSelf="flex-end">SUBMIT</Button>
                                </Stack>

                            </GridItem>

                        </SimpleGrid>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>
    );
}

export default Process;