import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";


import Logo from '../assets/img/AIME SPORTS & MEDIA 8.svg'
const NavBar = (props) => {
    const { selectedContract } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const history = useHistory()

    const handleRedirect = useCallback((path) => {
        return () => {
            history.push(path);
        }
    }, [])
    return (
        <NavBarContainer {...props}>
            <a href={'/'}>
                 <Image src={Logo}w="58px"mb={0}/>
             </a>
            <MenuToggle toggle={toggle} isOpen={isOpen} />
            <EndLinks toggle={toggle} isOpen={isOpen} />
        </NavBarContainer>
    );
};

const CloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
            fill="#636F8E"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg>
);

const MenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="#636F8E">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
    return (
        <Box
            _hover={{ cursor: "pointer" }}
            display={{ base: "block", md: "none" }}
            transition="0.2s ease-in-out "
            onClick={toggle}>
            {isOpen ? <CloseIcon /> : <MenuIcon />}
        </Box>
    );
};

const MenuItem = ({ icon, children, isLast, toggle, nohash=false, to = "/", ...rest }) => {
    return (
        <a href={nohash? to :`/#${to}`}>
            <Text onClick={() => toggle()} p={2} color="black" _hover={{  color:"#636F8E"}} fontWeight="800" fontSize="14px" display="block" {...rest}>
                {children}
            </Text>
        </a>
    );
};

const EndLinks = ({ isOpen, toggle }) => {
    const history = useHistory()

    const handleRedirect = useCallback((path) => {
        return () => {
            history.push(path);
        }
    }, [])
    return (
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            pb={["20px", 0, 0, 0]}
            flexBasis={{ base: "100%", md: "auto" }}>
            <Stack
          
                spacing={["10px","10px","10px", "50px"]}
                mr={["0", "60px"]}
                align="center"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={["column", "column", "row", "row"]}
                mt={[4, 4, 0, 0]}
                pt={[4, 4, 0, 0]}>

                <MenuItem toggle={toggle} to="hero"  > Home </MenuItem>
                <MenuItem toggle={toggle} to="solutions" isLast>Solutions </MenuItem>
                <MenuItem toggle={toggle} to="process" isLast>Our Process</MenuItem>
                <MenuItem toggle={toggle} to="works" isLast>How We Work</MenuItem>
                <MenuItem toggle={toggle} to="team" isLast>Team </MenuItem>
                <MenuItem toggle={toggle} to="contact"  isLast>Contact</MenuItem>
               

            </Stack>
        </Box>
    );
};

const NavBarContainer = ({ children, ...props }) => {
    return (
        <Flex
            as="nav"
            justify="space-between"
            wrap="wrap"
            w="100%"  
            color={"black"}
            pos="fixed"
            top="0" left="0"
            zIndex="100"
            alignItems={["center","center"]}
            boxShadow="md"
            px={8}
            pt={2}
            bg="white"
            {...props}>
            {children}
        </Flex>
    );
};



export default NavBar;