import { Box, GridItem, Image, ListItem, SimpleGrid, Stack, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import ChatShop from '../assets/img/chat_shop_logo.svg';


function Customer(props) {
    return (
        <Box mb="200px" bg="#EDEEF3">

            <SimpleGrid columns={[1, 4]} p={8}
                gap={12} mt="50px">
                <GridItem colSpan={[4, 1]} >
                    <Stack color="white" fontSize="40px" fontWeight="900" w="302px" h="200px" >
                        <Image src={ChatShop} />
                    </Stack>
                    <Stack spacing={6}>
                        <Stack>

                            <Text fontSize="16px" fontWeight="700" textAlign="center" mb={2} color="#636F8E" >Testimonial</Text>
                            <Text fontSize="16px" lineHeight="25px" textAlign="center" color="#636F8E" >
                                “AIME has thrown the development rule book out the window, which can feel unusual, but the way AIME goes about producing technology is superb and will change your perception of what can be achieved in a short space of time.”
                            </Text>
                            <Text fontSize="16px" fontWeight="700" color="#636F8E" lineHeight="25px" textAlign="center" >
                                Joe Bush, CEO at The Chat Shop

                     </Text>
                        </Stack>


                    </Stack>
                </GridItem>
                <GridItem colSpan={[4,3]} p={0}>
                    <Stack spacing={8}>
                    <Text fontSize="26px" fontWeight="900" lineHeight="25px" color="#636F8E">CLIENT CASE STUDY</Text>
                        <Stack>

                           
                            <Text fontSize="26px" fontWeight="700" lineHeight="25px"  color="#636F8E">Project Overview

</Text>
                            <Text fontSize="14px" lineHeight="25px" color="#636F8E">
                                Developing natural language processing technology to automate processes for The Chat Shop

                            </Text>
                        </Stack>
                        <Stack>

                            <Text fontSize="26px" fontWeight="700" lineHeight="25px" color="#636F8E">Project Needs
</Text>
                            <Text fontSize="16px" lineHeight="25px" color="#636F8E">
                            The Chat Shop is a full-service conversation agency that uses its AI chat technology to fast track the customer journey via live chat and chatbot solutions that excite and delight customers. <br/>
                            Having managed over 3 million conversations, The Chat Shop recognised an opportunity to parse conversation data to gain insights into how people talk about various facets. As such, it needed to develop a platform that could analyse all transcripts through an NLP engine to extract rich insights, intents and sentiments to drive better results for clients.  

                        </Text>
                        </Stack>
                        <Stack>

                            <Text fontSize="26px" fontWeight="700" lineHeight="25px" color="#636F8E">Why AIME Was The Right Solution
</Text>
                           <UnorderedList>
                               <ListItem  color="#636F8E">
                               Such a demanding project would take significant time, money and resource, however, AIME has allowed The Chat Shop to create something clever and that looks good, fast – minus the substantial cost  

                               </ListItem>
                               <ListItem  color="#636F8E">
                               Project managers support the entire process, which means The Chat Shop doesn’t need to be heavily involved and can focus on running the business day-to-day
 
                               </ListItem>
                           </UnorderedList>
                        </Stack>
                       
                    </Stack>
                </GridItem>


            </SimpleGrid>

        </Box>
    );
}

export default Customer;