import { Box, Center, Grid, GridItem, HStack, Image, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Logo from '../assets/img/AIME7.svg'
import BlogImage from '../assets/img/Blog_heading_2.svg'
import { exampleBlogPosts } from '../utils/dummy';

function Blog(props) {
    return (
        <Stack align="center" justify="center" w="100%"p={[6,0]}>
            <Stack px={["10px", "60px"]} h="calc(100vh - 62px)" mb="200px">
                <Stack w="fit-content" pt={["50px","100px"]} mb={["5px","35px"]}>
                   
                    <Image src={BlogImage} w="689px" h="117px"/>
                </Stack>
                <Stack direction={["column", "row"]} spacing={8}  w={["100%", "fit-content"]}
                    >
                    <GridItem rowSpan={2} colSpan={2} mr={9} >
                        <Box >
                            <Image src={Logo}w="120px"h="120px" />
                        </Box>
                        <Text textAlign="start" my={5} w={["100%", "286px"]} color="black"fontSize="18px"lineHeight="28px">
                            Just like our company philosophy
                            we encorage free speech and ideas
                            that spark debate.
                    </Text>
                    </GridItem>
                    <Stack spacing={8} mb="30px">

                        {exampleBlogPosts.map((blog, index) => (
                            <GridItem colSpan={4} p={0} mr={["0", "50px"]} >
                                <Stack w={["100%", '750px']}  >
                                    <Text my={0} color="#636F8E" fontSize="26px" fontWeight="600" lineHeight="25px">{blog.title}</Text>
                                    <Text color="#636F8E" fontSize="16px" lineHeight="25px">{blog.date} | {blog.type} </Text>
                                    <Text color="#636F8E" fontSize="16px" lineHeight="25px" >{blog.summary}</Text>
                                    <Text><Link href={`/blog/post/?id=${blog.id}`} color="#FF0808" fontSize="14px" >read more...</Link></Text>
                                </Stack>
                            </GridItem>
                        ))}
                    </Stack>
                </Stack>

            </Stack>
        </Stack>
    );
}

export default Blog;