import {
    Box, Center, Flex, Image, SimpleGrid, Stack, Text, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import TuesdayLogo from '../assets/img/tuesday2.png'
import Dinegenius from '../assets/img/dinegenius_logo.svg'
import Clausely from '../assets/img/causaly_logo.svg'
import Babbu from '../assets/img/babbu.svg'
import ChatShop from '../assets/img/tcs_purple.png'
import Crowd from '../assets/img/crowd_lords.svg'
import Yada from '../assets/img/yada.png'
import { Link, useHistory } from 'react-router-dom';
import Customer from './Customer';
import BabbuCasestudy from './BabbuCasestudy';
import ClausalyCasestudy from './ClausalyCasestudy';




function ClientsandVentures(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isModal2open, onOpen: onModal2open, onClose: onModal2close } = useDisclosure()
    const { isOpen: isModal3open, onOpen: onModal3open, onClose: onModal3close } = useDisclosure()

    const history = useHistory()

    const handleRedirect = useCallback((path) => {
        return () => {
            history.push(path);
        }
    }, [])
    const Chatcasestudy = () => {
        history.push('/customer')
    }
    const showCustomermodal = () => {



    }
    return (
        <>
            <a name="client"></a>
            <Stack py={["30px", "60px"]}px={[4,0]} align="center" justify="center" w="100%">
                <Stack spacing={0}>
                    <Text fontSize={["24px", "35px"]} fontWeight="700" textAlign="center" m={0} color="#535b7c">Clients &amp; Ventures</Text>

                    <Text fontSize={["16px", "18px"]} lineHeight={["22px", "32px"]} textAlign="center" my={["20px", "30px"]} color="#636F8E" >
                        Some of our recent projects, from fee-based engagements to joint ventures.
            </Text>
                </Stack>

                <SimpleGrid columns={[2,2,2,4]} direction={["column", "row"]}  mb="30px" w={["100%", "1100px"]} gap={[4, 8]} justify="space-between"  >
                   
                        <Center>  <Image src={Yada} w="102px" /></Center>
                        <Center> <Image src={Clausely} w="172px" /></Center>
                    
                        <Center>  <Image src={Babbu} w={["141px", "184px"]} /></Center>
                        <Center>  <Image src={ChatShop} w={["147px","237px" ]}/></Center>
                   


                </SimpleGrid>

                <Stack w={["100%", "980px"]} justify="center" w="100%" direction={["column", "row"]}  spacing={12}>

                    <Stack w={["100%", "100%", "100%", "289px"]} h={["fit-content", "100%", "100%", "270px"]} spacing={[1, 5]} textAlign="center" justify={["flex-start", "space-between"]}>
                        <Text fontWeight="700" fontSize="24px" color="#636F8E">Testimonial</Text>
                        <Text fontSize="16px" textAlign="center" minH={["fit-content", "148px"]} color="#636F8E" >
                            “Working with the team at AIME has made a complicated process simple;
                            I always speak with the same people, processes have been streamlined, and it's so easy to give feedback and be totally honest. Nothing has proven to be too much.”
                     </Text>
                        <Text pt="10px" fontSize="16px" fontWeight="700" textAlign="center" color="#636F8E" >
                            Charlie Rosier, CEO at Babbu  </Text>
                        <Text onClick={onModal2open} borderBottom="0.25px solid #636F8E" alignSelf="center" w="117px" fontSize="16px" color="#636F8E" _hover={{  color:"#000000", cursor:"pointer", borderBottom:"0.25px solid #000000"}} >Full case study </Text>
                    </Stack>
                    <Stack w={["100%", "100%", "100%", "271px"]} h={["fit-content", "100%", "100%", "270px"]} my="20px" spacing={[1, 5]} textAlign="center" justify={["flex-start", "space-between"]}>
                        <Text fontWeight="700" fontSize="24px" color="#636F8E">Testimonial</Text>
                        <Text fontSize="16px" textAlign="center" minH={["fit-content", "148px"]} color="#636F8E" >
                            “AIME has thrown the development rule book out the window, which can feel unusual, but the way AIME goes about producing technology is superb and will change your perception of what can be achieved in a short space of time.”
                     </Text>
                        <Text pt="10px" fontSize="16px" fontWeight="700" textAlign="center" color="#636F8E" >
                            Joe Bush, CEO at The Chat Shop </Text>
                        <Text onClick={onOpen} borderBottom="0.25px solid #636F8E" alignSelf="center" w="117px" fontSize="16px" color="#636F8E"_hover={{  color:"#000000", cursor:"pointer", borderBottom:"0.25px solid #000000"}}>Full case study </Text>
                    </Stack>


                    <Stack w={["100%", "100%", "100%", "327px"]} h={["fit-content", "100%", "100%", "270px"]} my="20px" spacing={[1, 5]} textAlign="center" justify={["flex-start", "space-between"]}>
                        <Text fontWeight="700" fontSize="24px" color="#636F8E">Testimonial</Text>
                        <Text fontSize="16px" textAlign="center" minH={["fit-content", "148px"]} color="#636F8E" >
                            “AIME is trying to crack real problems in an intelligent manner. The team are adaptable and their approach is collaborative, pragmatic and issue-focused, which I value. Furthermore, they’ll go above and beyond to bring a concept to life, however aren’t afraid to be honest if something isn’t possible.”
                     </Text>
                        <Text pt="10px" fontSize="16px" fontWeight="700" textAlign="center" color="#636F8E" >
                            John Viner-Smith, CEO at Clausaly</Text>
                        <Text onClick={onModal3open} borderBottom="0.25px solid #636F8E" alignSelf="center" w="117px" fontSize="16px" color="#636F8E"_hover={{  color:"#000000", cursor:"pointer", borderBottom:"0.25px solid #000000"}}>Full case study </Text>
                    </Stack>


                </Stack>

            </Stack>

            <Modal isOpen={isOpen} onClose={onClose} size="6xl" maxH="511px" p={0} isCentered scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>

                    <ModalCloseButton />
                    <ModalBody>
                        <Customer />
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal isOpen={isModal2open} onClose={onModal2close} size="6xl" maxH="511px" p={0} isCentered scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>

                    <ModalCloseButton />
                    <ModalBody>
                        <BabbuCasestudy />
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal isOpen={isModal3open} onClose={onModal3close} size="6xl" maxH="511px" p={0} isCentered scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>

                    <ModalCloseButton />
                    <ModalBody>
                        <ClausalyCasestudy />
                    </ModalBody>

                </ModalContent>
            </Modal>

        </>

    );
}

export default ClientsandVentures;