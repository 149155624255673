import { Box, Button, Flex, FormControl, GridItem, HStack, Input, ListItem, SimpleGrid, Stack, Text, Textarea, UnorderedList } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useToast } from "@chakra-ui/react"

function Contact(props) {
    const { register, errors, handleSubmit, reset } = useForm();


    const onSubmit = async (data) => {
        try {
            const templateParams = {
                name: data.name,
                email: data.email,
                calc: data.calc,
                message: data.message
            };
            await emailjs.send(
                "service_@essie",
                "template_@essie",
                templateParams,
                "user_654yod8kyuHrU9FQhauJh"
            ).then(res=>{
                toastifySuccess(); 
            });
            reset();
           
        } catch (e) {
            console.log(e);
        }

        
    };
    const toastifySuccess = () => {
        toast.success('Form sent!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit',
            toastId: 'notifyToast'
        });
    };

    return (
        <>
            <a name="contact"></a>

            <Stack spacing={8} pb={["40px","80px"]}pt="69px" id="contact" bg="#EDEEF3"w="100%">
                <Stack px={["10px", "40px"]} align="center" spacing={3} >
                    <Stack >
                    <Text fontSize={["24px","35px" ]}textAlign="center" fontWeight="700" color="#636F8E" >Contact us</Text>
                    <Text fontSize={["16px","18px"]} textAlign="center" mb={["5px","57px"]} color="#636F8E"px={["10px","0px"]}  >
                    Drop us a line and someone will get back to you shortly.



</Text>
                        </Stack>


                    <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Stack direction={['column', 'row']} w="100%" justify="space-between" spacing={[5,12]}>

                            <Stack spacing={7}>

                                <Stack direction={["column", "row"]} ml={0} spacing={8} mb={4}>
                                    <FormControl>
                                        <Input borderRadius="0" 
                                        p={3} textAlign="start" 
                                        border="none" w="100%" 
                                        placeholder="CONTACT NAME"
                                         borderBottom="0.5px solid #575759"
                                         textColor="#575759"
                                         fontSize="14px"
                                            type="text"
                                            name="name"
                                            {...register('name', { required: true })}
                                        />

                                    </FormControl>

                                    <FormControl>
                                        <Input borderRadius="0" p={3} textAlign="start" border="none" w="100%" placeholder="EMAIL ADDRESS" borderBottom="0.5px solid #575759"textColor="#575759"
                                         fontSize="14px"
                                            type='email'
                                            name='email'
                                            {...register('email', { required: true })}
                                        />

                                    </FormControl>



                                </Stack>


                                <Stack spacing={8} direction={["column", "row"]} w={["100%", "100%", "500px", "771px"]} >
                                    <Textarea p={3} borderRadius="0"
                                        variant="unstyled" p={3}
                                        textAlign="start"
                                        bg="#C4C4C4"
                                        placeholder="MESSAGE"
                                        h="110px"
                                        name='message'
                                        {...register('message', { required: true })}
                                    />

                                </Stack>

                            </Stack>
                            <Stack direction={["row","column"]} spacing={6} justify="flex-end" w="100%">
                                <HStack spacing={2} alignItems="center" justify="end">
                                    <Text color="#575759" fontSize={["14px","16px"]}  >2 + 14 =</Text>
                                    <Input border="1px solid #636F8E" w="80px" h="41px" borderRadius="none" type="number"  {...register('calc', { required: true })} />
                                </HStack>
                          
                                <Button border="none" color="white" fontSize="16px" textAlign="center"
                                    fontWeight="700" w={["150px", "150px"]} h="50px" borderRadius="5px" bg="#636F8E" type="submit" 
                                   
                                    >SUBMIT</Button>
                            </Stack>

                        </Stack>
                        <ToastContainer />
                    </form>
                </Stack>
            </Stack>
        
        </>

    );
}

export default Contact;