import { Box, Button, Flex, GridItem, HStack, Image, Input, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid, Stack, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import MvpRun from '../assets/img/mvprunimage.png';
import Ventures from '../assets/img/venturesimage.png';
import Enterprise from '../assets/img/enterpriceimage.png';
import Saas from '../assets/img/saasimage.png';
import aimelogo from '../assets/img/AIME SPORTS & MEDIA 8.svg';
import aimeventures from '../assets/img/AIME_VENTURES6.svg';
import aimemvp from '../assets/img/MVP_Run 4.svg';
import Logo from '../assets/img/AIME7.svg'
import Close from '../assets/img/add.svg'
import 'react-toastify/dist/ReactToastify.min.css';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';

function Services(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, errors, handleSubmit, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            const templateParams = {
                idea: data.idea,
                person: data.person,
                stage: data.stage,
                name: data.name,
                email: data.email,
                phone: data.phone,
                calc: data.calc,
                message: data.message
            };
            await emailjs.send(
                "service_ijambl6",
                "template_2rx9usj",
                templateParams,
                "user_lt17hCnwbWbm3vMINFvZV"
            ).then(res=>{
                onClose();
                toastifySuccess(); 
            });
            reset();
           
        } catch (e) {
            console.log(e);
        }

        
    };
    const toastifySuccess = () => {
        toast.success('Form sent!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit',
            toastId: 'notifyToast'
        });
    };
    return (
<>
        <Stack  px={[0, 4]}bg="#EDEEF3"w="100%"align="center"m={0} >
             <a name="mvprun" ></a>
            <Stack direction={["column-reverse", "row"]}pt="62px" spacing={12} gap={10} w="fit-content" >
                <GridItem >
                    <Stack align="center">
                        <Text fontSize="24px" fontWeight="700" textAlign="start" color="#636F8E" pt={["20px", "0"]}>AIME Sports & Media MVP.Run</Text>
                        <Text fontSize="16px" lineHeight={["22px", "25px"]} textAlign="center" w={["100%", "600px"]} px={[6, 0]} color="#636F8E" >
                             New concept or existing business with a new idea…<br/>
                             We create MVPs at speed. Allowing you to test your live product in the <br/>market in under 8 weeks. </Text>
                             <Text fontSize="18px"fontWeight="700"color="#636F8E">
                             only £10,000 
                             <Text as="span" fontSize="18px"fontWeight="400"> (exc. VAT)</Text>
                             </Text>
                             <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E"onClick={onOpen} >PITCH IDEA</Button>
                    </Stack>


                </GridItem>
                <GridItem>
                    <Flex justify="center" align="center">
                        <Box>
                            <Image src={MvpRun} />
                            <Image src={aimemvp} w="50px" mt="-55px" ml="5px" />
                        </Box>

                    </Flex>
                </GridItem>

            </Stack>
            {/* Enterprise */}
            
            <Stack id="enterprice" direction={["column", "row"]} spacing={12} py={["30px", "62px"]} gap={10} w="fit-content" >
                <GridItem >
                    <Flex justify="center" align="center">
                        <Box>
                            <Image src={Enterprise} alignSelf="center" />
                            <Image src={aimelogo} w="50px" mt="-55px" ml="5px" />
                        </Box>

                    </Flex>
                </GridItem>

                <GridItem colSpan={1}  >
                    <Stack align="center">
                        <Text fontSize="24px" fontWeight="700" textAlign="center" color="#636F8E" pt={["20px", "0"]}>AIME Sports & Media Enterprise</Text>
                        <Text fontSize="16px" lineHeight={["22px", "25px"]} textAlign="center" w={["100%", "607px"]} px={[6, 0]} color="#636F8E">
                        Improve your MVP, add new features, evolve and grow as your <br/>
                        business does.
                        Your ongoing technology partner.
                        </Text>
                        <Text fontSize="18px"fontWeight="700"color="#636F8E">
                             only £5,000  per month
                             <Text as="span" fontSize="18px"fontWeight="400"> (exc. VAT)</Text>
                             </Text>
                             <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E"onClick={onOpen} >PITCH IDEA</Button>
                    </Stack>


                </GridItem>


            </Stack>
            {/* ventures */}
            <Stack id="ventures" direction={["column-reverse", "row"]} spacing={12} py={["30px", "62px"]} gap={10} w="fit-content" >
                <GridItem colSpan={1} >
                    <Stack align="center" pt={["20px", "0"]}>
                        <Text fontSize="24px" fontWeight="700" textAlign="center" color="#636F8E" >AIME Sports & Media Ventures</Text>
                        <Text fontSize="16px" lineHeight={["22px", "25px"]} textAlign="center" w={["100%", "607px"]} color="#636F8E" px={[6, 0]} >
                        Need to grow – pitch us today. <br/>

We can be your CTO, development house, advisors and investors.

                    </Text>
                    <Text fontSize="18px"fontWeight="700"color="#636F8E">
                    Negotiable
                             </Text>
                             <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E"onClick={onOpen} >PITCH IDEA</Button>
                    </Stack>

                </GridItem>
                <GridItem>
                    <Box>
                        <Image src={Ventures} />
                        <Image src={aimeventures} w="50px" mt="-55px" ml="5px" />
                    </Box>

                </GridItem>

            </Stack>
            
            

        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>
                   
                  
                    <ModalBody>
                        <HStack w="100%"justify="flex-end">
                        <Image src={Close} w="25px"alignSelf="flex-end"m={2} onClick={onClose}/>
                        </HStack>
                    
                        <SimpleGrid columns={[1,5]} gap={12} p={7} >
                            <Box>
                                <Image src={aimelogo} />
                            </Box>
                            <GridItem colSpan={4} w={["100%","344px"]}>
                                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Stack spacing={8} >
                                    <Text fontSize="20px" fontWeight="900" color="#636F8E">PITCH YOUR BUSINESS CONCEPT</Text>
                                    <Textarea
                                        placeholder="Tell us more about your business concept"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                        textColor="#636F8E"
                                        fontSize="14px"
                                        type="text"
                                        name="idea"
                                        {...register('idea', { required: true })}
                                    />
                                    <Textarea
                                        placeholder="Why are you the right person/people to do this? "
                                        textColor="#636F8E"
                                        fontSize="14px"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                        type="text"
                                        name="person"
                                        {...register('person', { required: true })}
                                    />
                                    <Textarea
                                        placeholder="What stage is your concept at?"
                                        textColor="#636F8E"
                                        fontSize="14px"
                                        w={["100%","344px"]}
                                        h="107px"
                                        border="1px solid #999999"
                                        borderRadius="4px"
                                        boxSizing="border-box"
                                        type="text"
                                        name="stage"
                                        {...register('stage', { required: true })}
                                        
                                    />
                                    <Input
                                        placeholder="CONTACT NAME"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                        type="text"
                                        name="name"
                                        {...register('name', { required: true })}
                                    />
                                    <Input
                                        placeholder="EMAIL ADDRESS"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                        type="text"
                                        name="email"
                                        {...register('email', { required: true })}
                                    />
                                    <Input
                                        placeholder="PHONE (OPTIONAL)"
                                        w={["100%","344px"]}
                                        h="35px"
                                        textColor="#575759"
                                        fontSize="14px"
                                        border="none"
                                        borderRadius="none"
                                        borderBottom="1px solid #999999"
                                        boxSizing="border-box"
                                        type="number"
                                        name="phone"
                                        {...register}
                                    />
                                    <Button type="submit" border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E" _hover={{ cursor: "pointer", bg: "#EDEEF3", color: "#636F8E" }} onClick={onOpen} alignSelf="flex-end">SUBMIT</Button>
                                </Stack>
                                </form>

                            </GridItem>

                        </SimpleGrid>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>

    );
}

export default Services;