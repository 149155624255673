import { Stack } from '@chakra-ui/react';
import React from 'react';
import ClientsandVentures from './ClientsandVentures';
import Contact from './Contact';
import Footer from './Footer';
import HeroSection from './HeroSection';
import HowitWorks from './HowitWorks';
import LatestNews from './LatestNews';
import Process from './Process';
import Services from './Services';
import Solutions from './Solutions';
import Team from './Team';


function Home(props) {
  
    return (
        <>
 
            <Stack overflowY="hidden"align="center"spacing={0}>
                <HeroSection /> 
                <Solutions />
                <Process/>
                <HowitWorks />
                <Services />
                <Team />
                <Contact />
                <Footer />
               
            </Stack >
        </>
    );
}

export default Home;