import { Box, Button, Center, Flex, GridItem, HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Cone from '../assets/img/cone_mvp.svg'
import Cloud from '../assets/img/cloud_dark.svg'
import Lightbulb from '../assets/img/lightbulb.svg'
import Infinity from '../assets/img/infinityalt.svg'
import { Link } from 'react-router-dom';


function Solutions(props,) {

    return (
        <>
            <a name="solutions"></a>
            <Stack align="center" justify="center" w="100%" bg="#EDEEF3" pb={["43px","86px"]} pt={["39px", "78px"]} px="15px" >

                <Text fontSize={["24px", "32px"]} textAlign="center" fontWeight="700" m={0} color="#636F8E" >Aime Sports & Media Solutions</Text>
                <Text fontSize={["16px", "18px"]} textAlign="center" my={["10px", "25px"]} color="#636F8E" w={["100%", "720px"]}>
                    We are a business builder – helping you get from idea to business.<br/>

                    We specialise in creating digital products, for scale, at speed.

            </Text>
                <SimpleGrid columns={[1, 2, 3, 3]} gap={12} alignSelf="center" justifySelf="center" w="70%">
                    {/* card 1 */}
                    <GridItem>

                        <Stack direction="column" alignItems="center" justify="space-between" >
                            <Center w="45px" minH="45px" >
                                <Image src={Cone} />
                            </Center>

                            <Text fontSize="24px" fontWeight="700" textAlign="center" color="#636F8E">MVP.Run</Text>
                          
                            <a href="#mvprun"> <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E" >SEE MORE</Button></a>
                        </Stack>

                    </GridItem>
                    {/* card 2 */}
                    <GridItem>
                        <Stack direction="column" alignItems="center" justify="space-between" >
                            <Center w="45px" minH="45px" >
                                <Image src={Infinity} />
                            </Center>

                            <Text fontSize="24px" fontWeight="700" textAlign="center" color="#636F8E"> Enterprise</Text>
                          
                            <a href="#enterprice">  <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E"  >SEE MORE</Button></a>
                        </Stack>

                    </GridItem>
                    <GridItem>
                        <Stack direction="column" alignItems="center" justify="space-between" >
                            <Center w="45px" minH="45px" >
                                <Image src={Lightbulb} />
                            </Center>

                            <Text fontSize="24px" fontWeight="700" textAlign="center" color="#636F8E"> Ventures</Text>
                          
                            <a href="#ventures">  <Button border="none" color="white" fontSize="16px" textAlign="center" fontWeight="700" w="150px" h="50px" borderRadius="5px" bg="#636F8E"  >SEE MORE</Button></a>
                        </Stack>

                    </GridItem>
                  
                </SimpleGrid>

            </Stack>
        </>


    );
}

export default Solutions;