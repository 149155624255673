import { Box, Center, Flex, HStack, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';


function LatestNews(props) {
    const history = useHistory()

    const handleRedirect = useCallback((path) => {
        return () => {
            history.push(path);
        }
    }, [])
    return (
<Center bg="black" w="100%" p={[6,0]}>
        <Stack  pt={["2px","34px"]} pb={["30px","70px"]} align="center" justify="center" >
            <Center h={["fit-content","100px"]}  mb={["10px","20px" ]}w={["100%", "100%"]}borderRadius="5px">
                <Text color="white" fontSize={["24px","35px" ]}fontWeight="700" textAlign="center">
                    Latest news and views from our blog</Text>
            </Center>

            <Stack w={["100%","60vw"]}spacing={8} direction={["column", "row"]}>

                <Stack justify="space-between" bg="white" borderRadius="10px" py={3} px={["10px","20px"]} w={["100%", "425px"]}>
                    <Text  fontSize={["14px","16px"]} lineHeight={["18px","25px" ]}fontWeight="600"color="#636F8E">You shouldn’t be punished for a pivot.</Text>
                    <Text  fontSize={["14px","16px"]} lineHeight={["18px","25px" ]} color="#636F8E">Feb 16, 2021</Text>
                    <Text  fontSize={["14px","16px"]} lineHeight={["18px","25px" ]}color="#636F8E">
                        Eric Ries defined a pivot as
                    "...a change in strategy without a change in vision".<br />
                    hat distinction between strategy and vision is key.
                    The latter should be unwavering (vision),
                    whilst the other should be free to morph as necessary
                    to meet the hopes and dreams of that...
                   </Text>
                    <Text onClick={handleRedirect('/blog')}_hover={{color:"#29567f"}} color="red">read more...</Text>
                </Stack>
                <Stack justify="space-between" bg="white" mr={[0, "60px"]} borderRadius="10px" py={3} px={["10px","20px"]} w={["100%", "425px"]} >
                    <Text  fontSize={["14px","16px"]} fontWeight="600" lineHeight={["18px","25px" ]}color="#636F8E"> Is your startup idea strong enough?</Text>
                    <Text  fontSize={["14px","16px"]} lineHeight={["18px","25px" ]}color="#636F8E"> October 2 2021</Text>
                    <Text  fontSize={["14px","16px"]} lineHeight={["18px","25px" ]}color="#636F8E">
                    
                       

                        Not all ideas make for great businesses. In fact very few do,
                        and fewer still make for businesses that can easily scale and
                        deliver exponential returns. The good news is that ideas can be
                        easily tested before you jump in and put in the hard yards.
                        This is important as...
                    </Text>
                    <Text onClick={handleRedirect('/blog')}_hover={{color:"#29567f"}} color="red">read more...</Text>
                </Stack>



            </Stack>
        </Stack>
        </Center>
    );
}

export default LatestNews;