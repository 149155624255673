import { GridItem, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { createBreakpoints } from "@chakra-ui/theme-tools";
import React from 'react';
import Cogs from '../assets/img/cogs.svg';
import Group from '../assets/img/group.svg';
import Repeat from '../assets/img/repeat.svg';


function HowitWorks(props) {

    const breakpoints = createBreakpoints({
        sm: "30em",
        md: "48em",
        lg: "62em",
        xl: "80em",
        "2xl": "96em",
    })
    return (
        <>
            <a name="works"></a>
            <Stack align="center" pt={["40px", "80px"]} bg="#EDEEF3"w="100%"m={0} >

                <Text fontSize={["24px", "35px"]} textAlign="center" fontWeight="700" m={0} color="#636F8E">How We Work</Text>
                <Text w={["100%", "900px"]} fontSize={["16px", "18px"]} textAlign="center" color="#636F8E" px={["20px", "auto"]} mt={["10px", "30px"]}>
                    Our proprietary platform powers our proposition and those of our partners. Build your digital products faster and more cost effectively, without compromising on quality, scalability and security.
</Text>
                <SimpleGrid columns={[1, 2, 3, 3]} px={3} gap={[6, 12]} pt={["30px", "40px"]}>
                    {/* card 1 */}
                    <GridItem   >
                        <Stack alignItems="center" >
                            <Image src={Repeat} mb={[0, 4]} />
                            <Text fontSize="24px" fontWeight="700" textAlign="center" mb={[0, 2]} color="#636F8E">Repeatability</Text>
                            <Text w={["100%", "254px"]}  fontSize="16px" textAlign="center" color="#636F8E" >
                                With technology at our heart, we know what works from previous projects making it more cost effective for you.


                            </Text>
                        </Stack>


                    </GridItem>
                    {/* card 2 */}
                    <GridItem  >
                        <Stack alignItems="center" >
                            <Image src={Cogs} mb={[0, 5]} />
                            <Text fontSize="24px" fontWeight="700" textAlign="center" mb={[0, 2]} color="#636F8E" >Automation</Text>
                            <Text w={["100%", "323px"]}  fontSize="16px" color="#636F8E" lineHeight="25px" textAlign="center"  >
                                Why struggle when you can automate?<br/>

                                We have already done the hard work, building our propriety AIME technology. Simple functionality like payment processing can be automated saving you time.</Text>

                        </Stack>

                    </GridItem>
                    {/* card 3 */}

                    <GridItem >
                        <Stack alignItems="center" >
                            <Image src={Group} mb={[0, 5]} />
                            <Text fontSize="24px" fontWeight="700" textAlign="center" mb={[0, 2]} color="#636F8E">Efficiency</Text>
                            <Text w={["100%", "238px"]} h={["200px", "214px"]} fontSize="16px" lineHeight="25px" textAlign="center" color="#636F8E"  >
                            Life is short, so are our development cycles. Benefit from our in-house experienced team and agile methodology to get you live in under 8 weeks.</Text>

                        </Stack>
                    </GridItem>

                </SimpleGrid>

            </Stack>
        </>

    );
}

export default HowitWorks;