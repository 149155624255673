import { Box, Center, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import AimeLogo from '../assets/img/AIME SPORTS & MEDIA 8.svg'

function Footer(props) {
    return (
        <Center bg="black"w="100%" boxSizing="border-box">

       
        <Flex w={["100%","100%" ]} justifyContent="space-between" px={["20px", "40px"]}p={5} alignItems="center" h="127px">
            <Stack>
                <Text fontSize={["14px","16px"]}  color="white" >© 2021 THE AIME GROUP</Text>
                <Text fontSize={["14px","16px"]} color="white" >Innovation Without Limits</Text>
            </Stack>
            <Image src={AimeLogo} w={["44px","64px"]} />

        </Flex>
        </Center>
    );
}

export default Footer;