import { Box } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/styles/styles.css';
import Blog from './components/Blog';
import Customer from './components/Customer';
import NavBar from './components/Navbar';
import Home from './components/Home';
import BlogPost from './components/BlogPost';


function withNewLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <>
          <NavBar />
          <WrappedComponent />
        </>
      );
    }
  }
}

function App() {
  return (
    <>
      <Suspense>
        <Router>
          <Switch>
            <Route exact path="/" component={withNewLayout(Home)} />
            <Route exact path="/blog" component={withNewLayout(Blog)} />
            <Route path="/customer" component={withNewLayout(Customer)} />
            <Route path="/blog/post/" component={withNewLayout(BlogPost)} />
          </Switch>
        </Router>

      </Suspense>

    </>
  );
}

export default App;
