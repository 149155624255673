import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Center, Input, Textarea, Box, Link, Stack, Text, Checkbox } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { exampleBlogPosts } from '../utils/dummy';
import Footer from './Footer';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


function BlogPost(props) {
    const { register, errors, handleSubmit, reset } = useForm();
    const [selectedBlogPostID, setSelectedBlogPostID] = useState(0);
    const [blogPost, setBlogPost] = useState({});
    const [blogIDs, setBlogIDs] = useState([]);
    const [nextBlog, setNextBlog] = useState(null);
    const [prevBlog, setPrevBlog] = useState(null);
  
   

    const onSubmit = async (data) => {
        console.log("data:", data);
        try {
          const templateParams = {
            comment: data.comment,
            name: data.name,
            email: data.email,
            subject: data.website,
         
          };
          await emailjs.send(
            "service_@essie",
            "template_@essie",
            templateParams,
            "user_654yod8kyuHrU9FQhauJh"
          ).then(res=>{
            toastifySuccess(); 
        });
          reset(); 
        } catch (e) {
          console.log(e);
        }

      };

      const toastifySuccess = () => {
        toast.success('Form sent!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,  
          draggable: false,
          className: 'submit-feedback success',
          toastId: 'notifyToast'
        });
      };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        console.log("ID: ", id);
        setSelectedBlogPostID(id);
    }, []);

    useEffect(() => {
        exampleBlogPosts.map((blog) => {
            if (blog.id === selectedBlogPostID) {
                setBlogPost(blog);
            }
        })
    }, [selectedBlogPostID])

    useEffect(() => {
        exampleBlogPosts.map((blog, index) => {
            if (blog.id === selectedBlogPostID) {
                setBlogPost(blog);

                if (index > 0) {
                    setPrevBlog(exampleBlogPosts[index - 1]);
                }
                if (exampleBlogPosts[index + 1] !== undefined) {
                    console.log("Next blog: ", exampleBlogPosts[index + 1]);
                    setNextBlog(exampleBlogPosts[index + 1]);
                }
            }
            return;
        })

    }, [selectedBlogPostID])


    return (
        <Stack w="100%" align="center" >
            <Center w={["100%","70%"]} p={6}>

                <Stack pt="15%" key={blogPost.id}>

                    <Text fontSize="30px" fontWeight="500" pb="10px">{blogPost.title}</Text>
                    <Text fontSize="1.1em" pb="15px"> By {blogPost.author} | {blogPost.date} | {blogPost.type} | {blogPost.comments} </Text>
                    <div dangerouslySetInnerHTML={{ __html: blogPost.blog }}></div>

                </Stack>

            </Center>


            <Stack w={["100%","70%"]}spacing={5} py="30px" px={5}>
                <Box w="100%" align="center">
                    {prevBlog !== null &&
                        <Link href={`/blog/post/?id=${prevBlog.id}`}><Text w="fit-content" mr="auto" color="#fa8775"> <ChevronLeftIcon color="#fa8775" />{prevBlog.title} </Text></Link>
                    }
                    {nextBlog !== null &&
                        <Link href={`/blog/post/?id=${nextBlog.id}`}><Text w="fit-content" ml="auto" color="#fa8775"> {nextBlog.title} <ChevronRightIcon /></Text></Link>
                    }
                </Box>
                <Box>
                    <Button w="257px" bg="#fa8775" h="40px" borderRadius="20px" color="white" my="70px">
                        <Link href="/blog/">BACK TO HOME BLOG</Link>
                    </Button>
                </Box>
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Stack spacing={6}>
                <Text fontSize="28px" fontWeight="500">Submit a Comment</Text>
                <Text fontSize="14px">Your email address will not be published. Required fields are marked *</Text>
                <Textarea h="150px" bg="#eeeeee" placeholder="Comment" w="100%" borderRadius="none"type="text" {...register('comment', { required: true })}/>
                <Input h="50px"w={["100%","50%" ]} bg="#eeeeee" placeholder="Name*" borderRadius="none" type="text" isRequired name="name" {...register('name', { required: true })} />
                <Input h="50px"w={["100%","50%" ]} bg="#eeeeee" placeholder="Email*" borderRadius="none" type="email" isRequired name="email" {...register('email', { required: true })} />
                <Input h="50px"w={["100%","50%" ]}bg="#eeeeee" placeholder="Website" borderRadius="none"name="website" {...register} />
                <Checkbox value="save" name="save" type="radio" fontWeight="700"><Text  fontWeight="700">Save my name, email, and website in this browser for the next time I comment.</Text></Checkbox>
                <Button alignSelf="flex-end" bg="#fa8775" h="40px" borderRadius="20px"type="submit" color="white">Submit Comment</Button>
                </Stack>
                </form>
            </Stack>
            <Footer />
            <ToastContainer/>
        </Stack>
    );
}

export default BlogPost;