import { Box, Flex, HStack, Image, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Teamdata } from '../utils/dummy';
import ReadMoreReact from 'read-more-react';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Text _hover={{ cursor: 'pointer' }} onClick={toggleReadMore} className="text">
      {isReadMore ? text.slice(0, 54) : text}
      <span className="read-or-hide">
        {isReadMore ? "..." : ""}
      </span>
    </Text>
  );
};

function Team(props) {

  return (
    <>
      <a name="team"></a>
      <Box  w="100%" px={3} h={["fit-content","fit-content"]}>
        <Stack  pt={["40px","75px"]} align="center" justify="center">
          <Text fontSize={["24px","35px"]} textAlign="center" fontWeight="700" mb={["0px","10px" ]}color="#535b7c" >Meet Our Team</Text>
          <Text fontSize={["16px","18px"]} textAlign="center" mb="30px" color="#636F8E" >
            Our team includes successful entrepreneurs, developers, creatives, and product <br />
                specialists, all with a common passion for disrupting industries and making a
                difference.
            </Text>
          <SimpleGrid columns={[1,2]}pb={["40px","80px"]} gap={[4,12]} align="center" >

            {Teamdata.map((data) =>
              <Stack direction="column"justify="start" align="center" w="100%" spacing={0}>

                <Image src={data.image} h="190px" />
                <Text fontSize={["24px","20px" ]}fontWeight="700" textAlign="center" color="#636F8E" pt={6}>{data.name}</Text>
                <Text fontSize="16px" textAlign="center" color="#636F8E" mb={2}w="240px">{data.job_title} </Text>
                <Text fontSize="12px" color="#636F8E" textAlign="center" w={["100%", "194px"]} >
                  <ReadMore>
                    {data.description}
                  </ReadMore>
                </Text>

                <HStack _hover={{ cursor: 'pointer' }} pt="10px"align="center" justify="center" >
                {data.linkin &&
               <Link href={data.link}target="_blank"><Image src={data.linkin} w="18px" /></Link> 
                }
                  {data.twitter &&
                  <Link href={data.tlink}target="_blank"><Image src={data.twitter} w="18px"justifySelf="baseline"mb="-5px" /></Link> 
                  } 
                 
                </HStack>
              </Stack>
            )}


          </SimpleGrid>
        </Stack>
      </Box>
    </>
  );
}

export default Team;